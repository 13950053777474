import Account from './account/';
import Frame from './frame';
import Index  from './Home';
import API from '../API';
import ErrorPage from './errors';
import Request from './request';
import Team from './team';
import External from './external'
// import { Index } from './App';

export default [{
    path: '/',
    id: 'root',
    element: <Frame />,
    errorElement: <ErrorPage />,
    loader: async ()=> {
        return {
            session: await API.session.check().catch(() => null)
        };
    },
    children: [
        Index,
        Account,
        Request,
        Team,
        External,

    ]
}]