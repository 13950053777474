import React from 'react';

const Element = React.lazy(() => import('./Members.component'));

export const Members = {
    path: 'members',
    children: [
        {
            path: "*",
            element: <Element />,
        }
    ]
}