import { Outlet } from "react-router-dom";
import { Members } from './Members';
import React from "react";

const Index = React.lazy(() => import('./Index.component'));


export default {
    path: "detail",
    element: <Outlet />,
    children: [
        Members,
        {
            path: "*",
            element: <Index />
        },
    ],
}