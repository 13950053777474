import Detail from './detail';
import { Create } from './Create';
import { Outlet } from 'react-router-dom';
import React from 'react';


const Element = React.lazy(() => import('./index.component'));

export default {
    path: "team",
    element:  <Outlet />,
    children: [
        {
            index: true,
            element: <Element />
        },
        Detail,
        Create
    ],
}