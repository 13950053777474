import { Login } from "./Login";
import { Register } from "./Register";
import { Check } from "./Check";
import { Logout } from "./Logout";
import { Outlet } from "react-router-dom";


export default {
    path: "account",
    element: <Outlet />,
    children: [
        Login,
        Register,
        Check,
        Logout,
    ],
}