import { Outlet } from 'react-router-dom';
import { Login } from './Login';


export default {
    path: "external",
    element: <Outlet />,
    children: [
        {
            index: true,
            element: <></>
        },
        Login,
    ],
}