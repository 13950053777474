import { Outlet } from "react-router-dom";
import { List } from './List';
import { Home } from './Home';
import { Detail } from './Detail';

export default {
    path: "request",
    element: <Outlet />,
    children: [
        Home,
        List,
        Detail
    ],
}