import { useRouteError } from 'react-router-dom';
import Error404 from './404'
import Error500 from './500'

export default function () {
    let error = useRouteError() as RouteError;
    console.log(error);

    if (error.status == 404)
        return (<Error404 />);
    else if (error.status == 500)
        return (<Error500 />);
    else 
        return <>Unknown Error</>

}

type RouteError = {
    status: number
}